import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { ThemeModeContext } from '@luigiclaudio/ga-baseline-ui/theme-provider';
import { SiteLaunchTemplate } from '@luigiclaudio/ga-baseline-ui/site-launch';

export default () => {
    const intl = useIntl();
    const hasMounted = useHasMounted();
    const { themeMode } = useContext(ThemeModeContext);

    let isDarkModeOn = false;

    useEffect(() => {
        if (themeMode === 'darkMode') {
            isDarkModeOn = true;
        }
    }, [themeMode]);

    if (!hasMounted) {
        return null;
    }

    return (
        <SiteLaunchTemplate
            title={intl.formatMessage({ id: 'home.title' })}
            subtitle={intl.formatMessage({ id: 'home.subtitle' })}
            viewportHeight={100}
            valuePropositionColor={isDarkModeOn ? 'light' : 'dark'}
            logoPath="/assets/common/logo-dark.svg"
            logoPathAlt="/assets/common/logo-light.svg"
            alignMainItems="center"
        />
    );
};
